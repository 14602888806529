/* create a beautiful CSS for this JSX
    <div className="App">
      <header className="App-header">
        <h2>React App</h2>
        <p>React App with TypeScript</p>
      </header>
    </div>
 */

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
